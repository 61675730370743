export const environment = {
  envName: 'dev',
  production: false,
  apiUrl: 'https://api.impact.dev.kiwa.dev/v2/',
  frontUrl: 'https://impact.dev.kiwa.dev/',
  analyticsApiUrl: 'https://api.impact.dev.kiwa.dev/v2/',
  kmUrl: 'https://km.dev.kiwa.dev/',
  demoAccountId: 1,
  allowedDomains: ['api.impact.dev.kiwa.dev', 'km2dev.probreakerdev.com', 'api.design.kiwa.dev', 'api.km.dev.kiwa.dev'],
  disallowedDomains: [],
  googleMapsKey: 'AIzaSyANXjyEQwRSXKnQiiSuLoqA4k6e0oEs1II',
  oauthData: {
    microsoftOauth: {
      clientId: '6ff59212-5c7f-4f56-81a8-efb6bce63622',
      redirectUri: 'https://impact.dev.kiwa.dev/login',
      logoutRedirectUri: ''
    }
  },
  amplitudeKey: '50b2885a12692f5c136370b453ecde38',
  mawBucket: 'https://s3.eu-west-1.amazonaws.com/kita-dev-processed.kiwaimpact.com/',
  lessSecureAnalytics: [],
};
