import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class Helpers {
  static getCalendarLocale() {

    const l = moment().localeData();

    let sameDay = {},
      nextDay = {},
      lastDay = {},
      nextWeek = {},
      lastWeek = {},
      sameElse = {};
    try {
      // Something wrong with moment when lang RU selected, so lets use try-catch
      sameDay = l.calendar('sameDay');
      nextDay = l.calendar('nextDay');
      lastDay = l.calendar('lastDay');
      nextWeek = l.calendar('nextWeek');
      lastWeek = l.calendar('lastWeek');
      sameElse = l.calendar('sameElse');
    } catch (e) {}
    return {
      weekdays: l.weekdays(),
      weekdaysShort: l.weekdaysShort(),
      weekdaysMin: l.weekdaysMin(),
      months: l.months(),
      monthsShort: l.monthsShort(),
      longDateFormat: {
        LT: l.longDateFormat('LT'),
        LTS: l.longDateFormat('LTS'),
        L: l.longDateFormat('L'),
        LL: l.longDateFormat('LL'),
        LLL: l.longDateFormat('LLL'),
        LLLL: l.longDateFormat('LLLL'),
      },
      calendar: {
        sameDay: sameDay,
        nextDay: nextDay,
        lastDay: lastDay,
        nextWeek: nextWeek,
        lastWeek: lastWeek,
        sameElse: sameElse,
      },
      dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
      ordinal(_num: number): string {
        const num = Number(_num);
        const b = num % 10,
          // tslint:disable-next-line:no-bitwise
          output = ~~((num % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
        return num + output;
      },
      week: {
        dow: l.firstDayOfWeek(), // Monday is the first day of the week.
        doy: l.firstDayOfYear(), // The week that contains Jan 4th is the first week of the year.
      },
    };
  }
  static changeLocale(code) {
    moment.locale(code === 'en' ? 'en-gb' : code);
  }

  static leaf(obj, path) {
    path = path.split('.');
    for (let i = 0; i < path.length; i++) {
      obj == null ? (obj = '') : (obj = obj[path[i]]);
    }
    return obj;
  }

  static sort_by(field, reverse, primer) {
    const key = (x) => {
      return primer ? primer(Helpers.leaf(x, field)) : Helpers.leaf(x, field);
    };
    return (a, b) => {
      const A = key(a),
        B = key(b);
      return (A < B ? -1 : A > B ? 1 : 0) * [-1, 1][+!!reverse];
    };
  }

  static getSpecialTypes() {
    return ['photo', 'todouser', 'date', 'risks', 'risksmultiple', 'statictext', 'costcenter', 'affectedusers', 'gpslocation', 'customrisks_initial', 'customrisks_compare', 'staticimage', 'signature'];
  }

  static getListColors() {
    return [
      { name: 'todo', class: 'danger' },
      { name: 'positive', class: 'success' },
      { name: 'negative', class: 'danger' },
      { name: 'green', class: 'success' },
      { name: 'red', class: 'danger' },
      { name: 'blue', class: 'info' },
      { name: 'orange', class: 'warning' },
    ];
  }

  static showComponentLabel() {
    return ['radio', 'file', 'risks', 'risksmultiple', 'photo'];
  }

  static showComponentLabelOnObservationPage() {
    return ['title', 'radio', 'photo', 'spinner', 'risks', 'risksmultiple', 'file', 'statictext', 'gpslocation'];
  }

  static getTimeRanges() {
    return [
      {
        name: 'Today',
        data: [
          moment()
            .utc()
            .toDate(),
          null,
        ],
      },
      {
        name: 'Yesterday',
        data: [
          moment()
            .utc()
            .add(-1, 'days')
            .toDate(),
          moment()
            .utc()
            .add(-1, 'days')
            .toDate(),
        ],
      },

      {
        name: 'This Week',
        data: [
          moment()
            .utc()
            .startOf('isoWeek')
            .toDate(),
          null,
        ],
      },
      {
        name: 'Last Week',
        data: [
          moment()
            .utc()
            .subtract(1, 'weeks')
            .startOf('isoWeek')
            .toDate(),
          moment()
            .utc()
            .subtract(1, 'weeks')
            .endOf('isoWeek')
            .subtract(1, 'd')
            .toDate(),
        ],
      },

      {
        name: 'This Month',
        data: [
          moment()
            .utc()
            .startOf('month')
            .toDate(),
          null,
        ],
      },
      {
        name: 'Last Month',
        data: [
          moment()
            .utc()
            .subtract(1, 'months')
            .startOf('month')
            .toDate(),
          moment()
            .utc()
            .subtract(1, 'months')
            .endOf('month')
            .subtract(1, 'd')
            .toDate(),
        ],
      },
      {
        name: 'Last 30 Days',
        data: [
          moment()
            .utc()
            .subtract(30, 'days')
            .toDate(),
          null,
        ],
      },
      {
        name: 'Last 6 Months',
        data: [
          moment()
            .utc()
            .subtract(6, 'months')
            .startOf('month')
            .toDate(),
          null,
        ],
      },

      {
        name: 'This Year',
        data: [
          moment()
            .utc()
            .startOf('year')
            .toDate(),
          null,
        ],
      },
      {
        name: 'Last Year',
        data: [
          moment()
            .utc()
            .subtract(1, 'years')
            .startOf('year')
            .toDate(),
          moment()
            .utc()
            .subtract(1, 'years')
            .endOf('year')
            .subtract(1, 'd')
            .toDate(),
        ],
      },
      // { name: 'All', data: [null, null] },
    ];
  }

  static getColorClass(colors, name) {
    for (let f = 0; f < colors.length; f++) {
      if (colors[f].name === name) { return colors[f].class; }
    }
  }

  static slugify(string) {
    const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
    const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  static randomString(len) {
    let text = '';
    const length = len || 6;
    const possible = 'abcdefghijkmnopqrstuvwxyz23456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static getComponentDefaults() {
    return [
      {
        name: 'Title',
        type: 'title',
        settings: {
          required: false,
          size: 2
        },
      },
      {
        name: 'Radio',
        type: 'radio',
        settings: {
          items: [],
          fields: ['name', 'color'],
          required: false,
          useSecondaryEditor: false,
        },
      },
      {
        name: 'Select',
        type: 'select',
        settings: {
          items: [],
          fields: ['name'],
          required: false,
        },
      },
      {
        name: 'Text',
        type: 'text',
        settings: {
          required: false,
          singleRow: false,
        },
      },
      {
        name: 'Number',
        type: 'number',
        settings: {
          required: false,
        },
      },
      {
        name: 'Date',
        type: 'date',
        settings: {
          defaultValue: '',
          showTime: '',
          required: false,
        },
      },
      {
        name: 'Todo date',
        type: 'tododate',
        settings: {
          required: false,
        },
      },
      {
        name: 'Todo User',
        type: 'todouser',
        settings: {
          required: false,
        },
      },
      {
        name: 'Photo',
        type: 'photo',
        settings: {
          required: false,
        },
      },
      {
        name: 'Static Image',
        type: 'staticimage',
        settings: {
          required: false,
          images: []
        }
      },
      {
        name: 'Signature',
        type: 'signature',
        settings: {
          required: false,
        },
      },
      {
        name: 'Spinner',
        type: 'spinner',
        settings: {
          color: '',
          required: false,
        },
      },
      {
        name: 'Checkbox',
        type: 'checkbox',
        settings: {
          color: '',
          required: false,
        },
      },
      {
        name: 'Static Text',
        type: 'statictext',
        rawValue: '',
        settings: {
          required: false,
          size: 2
        }
      },
      {
        name: 'Risk assessment',
        type: 'risks',
        settings: {
          severity: [{ value: 1, name: 'Minor' }, { value: 2, name: 'Moderate' }, { value: 3, name: 'Major' }],
          probability: [{ value: 1, name: 'Unlikely' }, { value: 2, name: 'Likely' }, { value: 3, name: 'Very Likely' }],
          risk: [
            { value: 1, name: 'Very low' },
            { value: 2, name: 'Low' },
            { value: 3, name: 'Medium' },
            { value: 4, name: 'High' },
            { value: 5, name: 'Extreme' },
          ],
          required: false,
        },
      },
      {
        name: 'Custom Matrix - Initial',
        type: 'customrisks_initial',
        settings: {
          titles: {
            yAxis: 'Probability',
            xAxis: 'Severity',
            result: 'Risk'
          },
          structure: [
            [
              {name: 'Very likely', color: null, value: 3, desc: 'Occurs frequently', title: true},
              {name: 'Medium Risk', color: '#FFFF00', value: 3, desc: null, title: false},
              {name: 'High Risk', color: '#F3C3B5', value: 4, desc: null, title: false},
              {name: 'Extreme Risk', color: '#FE0000', value: 5, desc: null, title: false},
            ],
            [
              {name: 'Likely', color: null, value: 2, desc: 'Occurs several times', title: true},
              {name: 'Low Risk', color: '#97D357', value: 2, desc: null, title: false},
              {name: 'Medium Risk', color: '#FFFF00', value: 3, desc: null, title: false},
              {name: 'High Risk', color: '#F3C3B5', value: 4, desc: null, title: false},
            ],
            [
              {name: 'Unlikely', color: null, value: 1, desc: 'Improbable, but possible to occur', title: true},
              {name: 'Insignificant Risk', color: '#00B355', value: 1, desc: null, title: false},
              {name: 'Low Risk', color: '#97D357', value: 2, desc: null, title: false},
              {name: 'Medium Risk', color: '#FFFF00', value: 3, desc: null, title: false},
            ],
            // Bottom row so just the titles
            [
              {name: null, color: null, value: null, desc: null, title: false},
              {name: 'Minor', color: null, value: 1, desc: 'Minor or No treatment', title: true},
              {name: 'Moderate', color: null, value: 2, desc: 'Medical Treatment', title: true},
              {name: 'Major', color: null, value: 3, desc: 'Lost time injury', title: true},
            ],
          ],
          required: false,
        }
      },
      {
        name: 'Custom Matrix - Compare',
        type: 'customrisks_compare',
        settings: {
          titles: {},
          structure: [],
          required: false,
        }
      },
      {
        name: 'Risk assessment multiple',
        type: 'risksmultiple',
        settings: {
          severity: [{ value: 1, name: 'Minor' }, { value: 2, name: 'Moderate' }, { value: 3, name: 'Major' }],
          probability: [{ value: 1, name: 'Unlikely' }, { value: 2, name: 'Likely' }, { value: 3, name: 'Very Likely' }],
          risk: [
            { value: 1, name: 'Very low' },
            { value: 2, name: 'Low' },
            { value: 3, name: 'Medium' },
            { value: 4, name: 'High' },
            { value: 6, name: 'Extreme' },
            { value: 9, name: 'Super Extreme' },
          ],
          required: false,
        },
      },
      {
        name: 'File',
        type: 'file',
        settings: {
          required: false
        },
      },
      {
        name: 'User',
        type: 'affectedusers',
        settings: {
          required: false,
          status: 'private'

        }
      },
      {
        name: 'GPS Location',
        type: 'gpslocation',
        settings: {
          required: false,
        }
      }
    ];
  }

  static getParticipants(conversation) {
    if (conversation.participants) {
      let names = '',
        i = 0;
      conversation.participants.map(u => {
        if (i > 0) {
          names += ', ';
        }
        if (u.user) {
          names += u.user.fullname;
        } else {
          names += u.guest_email;
        }
        i++;
      });
      return names;
    } else {
      return '';
    }
  }

  /**
   *
   * @param permissions User's permissions object
   * @param target Name of the permission ITEM, for ex. 'default-tools'
   * @param level Level id. Use 0 for generic permission for target
   */
  static hasPermission(permissions: any, target: string, level: any = 'generic') {
    if (!permissions || permissions.length === 0) {
      return false;
    }
    if (!permissions[level]) {
      return false;
    }
    return permissions[level].indexOf(target) > -1;
  }

  // Analytics columns for Document list
  static getDocumentColumns() {
    return [
      {
        'field': 'list_id',
        'name': 'Document ID',
        'Style': {
          'max-width': '250px'
        },
        'selected': true
      },
      {
        'field': 'created',
        'name': 'created',
        'Style': {
          'max-width': '250px'
        },
        'selected': true
      },
      {
        'field': 'Auditor',
        'name': 'Auditor',
        'Style': {
          'max-width': '250px'
        },
        'selected': true
      },
      {
        'field': 'templateName',
        'name': 'templateName',
        'Style': {
          'max-width': '250px'
        },
        'selected': true
      },
      {
        'field': 'levelName',
        'name': 'levelName',
        'Style': {
          'max-width': '250px'
        },
        'selected': true
      },
      {
        'field': 'costCenterLevelName',
        'name': 'costCenterLevelName',
        'Style': {
          'max-width': '250px'
        },
        'selected': true
      },
    ];
  }
  static getTopics () {
    return [
      { topics: [] },
      {
        topics: [
          // eng
          '2.3 Other Hazard',
          '4. First-Aid Measures',
          '5. Firefighting measures',
          '6. Accidental Release Measures',
          '8.2 Exposure Controls',
        ],
      },
      {
        topics: [
          // fin
          '2.3 Muut Vaarat',
          '4. Ensiaputoimenpiteet',
          '5. Palontorjuntatoimenpiteet',
          '6. Toimenpiteet Onnettomuuspäästöissä',
          '8.2 Altistumisen Ehkäiseminen',
        ],
      },
      {
        topics: [
          // ger..
          '2.3 Sonstige Gefahren',
          '4. Erste-Hilfe-Maßnahmen',
          '5. Maßnahmen zur Brandbekämpfung',
          '6. Maßnahmen bei unbeabsichtigter Freisetzung',
          '8.2 Begrenzung und Überwachung der Exposition',
        ],
      },
      {
        topics: [
          // est...
          '2.3 Muud ohud',
          '4. Esmaabimeetmed',
          '5. Tulekustutusmeetmed',
          '6. Meetmed juhusliku sattumise korral keskkonda',
          '8.2 Kokkupuute ohjamine',
        ],
      },
      {
        topics: [
          // chi
          '2.3 Other Hazard',
          '4. First-Aid Measures',
          '5. Firefighting measures',
          '6. Accidental Release Measures',
          '8.2 Exposure Controls',
        ],
      },
      {
        topics: [
          // swe...
          '2.3 Andra faror',
          '4. Åtgärder vid första hjälpen',
          '5. Brandbekämpningsåtgärder',
          '6. Åtgärder vid oavsiktliga utsläpp',
          '8.2 Begränsning av exponeringen',
        ],
      },
      {
        topics: [
          // rus...
          '2.3 Other Hazard',
          '4. First-Aid Measures',
          '5. Firefighting measures',
          '6. Accidental Release Measures',
          '8.2 Exposure Controls',
        ],
      },
      {
        topics: [
          // ita...
          '2.3 Altri pericoli',
          '4. Misure di primo soccorso',
          '5. Misure di lotta antincendio',
          '6. Misure in caso di rilascio accidentale',
          '8.2 Controlli dell’esposizione',
        ],
      },
      {
        topics: [
          // dut...
          '2.3 Andere gevaren',
          '4. Andere gevaren',
          '5. Brandbestrijdingsmaatregelen',
          '6. Maatregelen bij het accidenteel vrijkomen van de stof of het mengsel',
          '8.2 Maatregelen ter beheersing van blootstelling',
        ],
      },
      {
        topics: [
          // fre...
          '2.3 Autres dangers',
          '4. Premiers secours',
          '5. Mesures de lutte contre l’incendie',
          '6. Mesures à prendre en cas de dispersion accidentelle',
          '8.2 Contrôles de l’exposition',
        ],
      },
      {
        topics: [
          // hung...
          '2.3 Egyéb veszélyek',
          '4. Elsősegély-nyújtási intézkedések',
          '5. Tűzoltási intézkedések',
          '6. Intézkedések véletlenszerű környezetbe jutás esetén',
          '8.2 Az expozíció elleni védekezés',
        ],
      },
      {
        topics: [
          // lat...
          '2.3 Citi apdraudējumi',
          '4. Pirmās palīdzības pasākumi',
          '5. Ugunsdzēsības pasākumi',
          '6. Pasākumi nejaušas noplūdes gadījumos',
          '8.2 Ekspozīcijas kontrole',
        ],
      },
      {
        topics: [
          // pol...
          '2.3 Inne zagrożenia',
          '4. Środki pierwszej pomocy',
          '5. Postępowanie w przypadku pożaru',
          '6. Postępowanie w przypadku niezamierzonego uwolnienia do środowisk',
          '8.2 Kontrola narażenia',
        ],
      },
      {
        topics: [
          // che...
          '2.3 Další nebezpečnost',
          '4. Pokyny pro první pomoc',
          '5. Opatření pro hašení požáru',
          '6. Opatření v případě náhodného úniku',
          '8.2 Omezování expozice',
        ],
      },
      {
        topics: [
          // nor...
          '2.3 Andre farer',
          '4. Førstehjelpstiltak',
          '5. Brannslokkingstiltak',
          '6. Tiltak ved utilsiktede utslipp',
          '8.2 Eksponeringskontroll',
        ],
      },
      {
        topics: [
          // lit...
          '2.3 Kiti pavojai',
          '4. Pirmosios pagalbos priemonės',
          '5. Priešgaisrinės priemonės',
          '6. Avarijų likvidavimo priemonės',
          '8.2 Poveikio kontrolės priemonės',
        ],
      },
      {
        topics: [
          // spa...
          '2.3 Otros peligros',
          '4. Primeros auxilios',
          '5. Medidas de lucha contra incendios',
          '6. Medidas en caso de vertido accidental',
          '8.2 Controles de la exposición',
        ],
      },
      {
        topics: [
          // por...
          '2.3 Outros perigos',
          '4. Medidas de primeiros socorros',
          '5. Medidas de combate a incêndios',
          '6. Medidas em caso de fuga acidental',
          '8.2 Controlo da exposição',
        ],
      },
      {
        topics: [
          // rom...
          '2.3 Alte pericole',
          '4. Măsuri de prim ajutor',
          '5. Măsuri de combatere a incendiilor',
          '6. Măsuri împotriva pierderilor accidentale',
          '8.2 Controale ale expunerii',
        ],
      },
      {
        topics: [
          // dan...
          '2.3 Andre farer',
          '4. Førstehjælpsforanstaltninger',
          '5. Brandbekæmpelse',
          '6. Forholdsregler over for udslip ved uheld',
          '8.2 Eksponeringskontrol',
        ],
      },
    ];
  }

  static getLanguageId() {
    return JSON.parse(localStorage.getItem('user')).language_id || 1;
  }

  static getTranslatedTemplateName(template, languageId) {
    if (!template) {
      return '';
    }

    if (template?.translations.length > 1) {
      const transArr = template.translations.filter((translation) => translation.language_id === languageId);
      let nameTrans = transArr.length > 0 ? JSON.parse(transArr[0].translations)[template.name] : template.name;

      if (!nameTrans || nameTrans.trim() === '') {
        nameTrans = template.name;
      }

      return nameTrans;
    } 
  
    return template.name;
  }
  
  static getHomeLevel(user, type: 'default' | 'cost_center', organisationId?: number) {
    const orgId = organisationId ?? user.organisation_id;
    return user.homeLevels?.find((level) => level.organisation_id === orgId && level.type === type) || null;
  }
}
